import React, { useRef, useMemo, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Color, Vector3, AdditiveBlending, TextureLoader } from 'three';

// Create circular particle texture
const createParticleTexture = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 64;
  canvas.height = 64;
  const ctx = canvas.getContext('2d');
  
  const gradient = ctx.createRadialGradient(32, 32, 0, 32, 32, 32);
  gradient.addColorStop(0, 'rgba(255,255,255,1)');
  gradient.addColorStop(1, 'rgba(255,255,255,0)');
  
  ctx.fillStyle = gradient;
  ctx.beginPath();
  ctx.arc(32, 32, 32, 0, Math.PI * 2);
  ctx.fill();
  
  return canvas;
};

// Constellation node component
function Node({ position, color, size, pulsating }) {
  const ref = useRef();
  
  useFrame(({ clock }) => {
    if (pulsating) {
      ref.current.scale.setScalar(1 + Math.sin(clock.getElapsedTime() * 4) * 0.3);
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[size, 32, 32]} />
      <meshBasicMaterial color={color} transparent opacity={0.8} />
    </mesh>
  );
}

function ConstellationField({ mousePosition }) {
  const ref = useRef();
  const time = useRef(0);
  const count = 100;

  // Create initial positions and colors
  const [positions, colors] = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);

    for (let i = 0; i < count; i++) {
      // Create a more natural distribution
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(Math.random() * 2 - 1);
      const r = 10 + Math.random() * 5;

      positions[i * 3] = r * Math.sin(phi) * Math.cos(theta);
      positions[i * 3 + 1] = r * Math.sin(phi) * Math.sin(theta);
      positions[i * 3 + 2] = r * Math.cos(phi);

      // Create color gradient from blue to purple
      const color = new Color();
      color.setHSL(0.6 + Math.random() * 0.1, 0.8, 0.6);
      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }

    return [positions, colors];
  }, []);

  // Create particle texture
  const particleTexture = useMemo(() => {
    const canvas = createParticleTexture();
    return new TextureLoader().load(canvas.toDataURL());
  }, []);

  useFrame((state) => {
    if (!ref.current) return;

    time.current = state.clock.getElapsedTime();
    const currentPositions = ref.current.geometry.attributes.position.array;

    for (let i = 0; i < count; i++) {
      const i3 = i * 3;
      const x = currentPositions[i3];
      const y = currentPositions[i3 + 1];
      const z = currentPositions[i3 + 2];

      // Natural floating movement
      const angle = time.current * 0.2 + i * 0.01;
      currentPositions[i3] = x + Math.sin(angle) * 0.02;
      currentPositions[i3 + 1] = y + Math.cos(angle) * 0.02;
      currentPositions[i3 + 2] = z + Math.sin(angle * 0.5) * 0.02;

      // Mouse interaction
      if (mousePosition.x !== 0 || mousePosition.y !== 0) {
        const dx = mousePosition.x * 20 - x;
        const dy = mousePosition.y * 20 - y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        
        if (dist < 5) {
          const force = (1 - dist / 5) * 0.2;
          currentPositions[i3] += dx * force;
          currentPositions[i3 + 1] += dy * force;
        }
      }
    }

    ref.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={ref}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={count}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          count={count}
          array={colors}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.5}
        sizeAttenuation={true}
        vertexColors={true}
        transparent={true}
        opacity={0.8}
        blending={AdditiveBlending}
        map={particleTexture}
        alphaTest={0.001}
      />
    </points>
  );
}

export default function InteractiveBackground() {
  const [mousePosition] = useState(new Vector3());

  const handleMouseMove = (event) => {
    mousePosition.x = (event.clientX / window.innerWidth) * 2 - 1;
    mousePosition.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  return (
    <div 
      style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        zIndex: -1,
        background: 'radial-gradient(circle at 50% 50%, #0a1527 0%, #000000 100%)',
      }}
      onMouseMove={handleMouseMove}
    >
      <Canvas
        camera={{ 
          position: [0, 0, 30],
          fov: 60,
        }}
        gl={{
          antialias: true,
          alpha: true,
          powerPreference: "high-performance",
        }}
      >
        <ConstellationField mousePosition={mousePosition} />
      </Canvas>
    </div>
  );
} 