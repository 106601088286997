import React from 'react';
import { Container, Typography, Box, Grid, IconButton, useTheme, Button } from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import Skills from './Skills';

const MotionTypography = motion(Typography);
const MotionBox = motion(Box);

export default function Home() {
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: { xs: 4, md: 8 }, mb: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={8}>
            <MotionBox
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              sx={{ mb: 6 }}
            >
              <MotionTypography
                variant="h6"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.primary.main,
                  mb: 2,
                  fontFamily: 'Space Mono, monospace',
                  fontSize: '1.2rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                }}
              >
                Welcome, I'm
              </MotionTypography>

              <MotionTypography
                variant="h1"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  background: 'linear-gradient(45deg, #00ffa3, #64ffda)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight: 'bold',
                  mb: 2,
                  filter: 'drop-shadow(0 0 2em rgba(0, 255, 163, 0.2))',
                }}
              >
                Michael Yarmoshik
              </MotionTypography>

              <MotionTypography
                variant="h2"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.text.secondary,
                  mb: 3,
                  fontSize: 'clamp(24px, 4vw, 32px)',
                }}
              >
                Crafting Digital Solutions with Code
              </MotionTypography>

              <MotionTypography
                variant="body1"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.text.secondary,
                  maxWidth: '600px',
                  mb: 4,
                  fontSize: '1.2rem',
                  lineHeight: 1.6,
                }}
              >
                UW Computer Science graduate and Software Engineer at Templar Screens, specializing in full-stack development and innovative solutions. From CAD systems to web applications, I bring ideas to life through clean, efficient code.
              </MotionTypography>

              <MotionBox
                variants={itemVariants}
                sx={{ display: 'flex', justifyContent: 'flex-start', mb: 6 }}
              >
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon sx={{ fontSize: '1.3rem' }} />}
                  href="/resume/Michael_Yarmoshik_Resume.pdf"
                  target="_blank"
                  sx={{
                    backgroundColor: 'rgba(77, 255, 181, 0.2)',
                    color: '#00ffa3',
                    padding: '14px 28px',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    borderRadius: '12px',
                    border: '1px solid rgba(77, 255, 181, 0.3)',
                    boxShadow: '0 6px 20px rgba(0, 255, 163, 0.2)',
                    '&:hover': {
                      backgroundColor: 'rgba(77, 255, 181, 0.3)',
                      transform: 'translateY(-4px)',
                      boxShadow: '0 10px 25px rgba(0, 255, 163, 0.3)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  Download Resume
                </Button>
              </MotionBox>
            </MotionBox>

            <MotionBox
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              sx={{ mb: 6 }}
            >
              <MotionTypography
                variant="h3"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.text.primary,
                  mb: 3,
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-8px',
                    left: 0,
                    width: '60px',
                    height: '4px',
                    background: 'linear-gradient(90deg, #00ffa3, #4D9FFF)',
                    borderRadius: '2px',
                  },
                }}
              >
                Experience Highlights
              </MotionTypography>

              <MotionTypography
                variant="body1"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.text.secondary,
                  maxWidth: '600px',
                  mb: 3,
                  fontSize: '1.1rem',
                  lineHeight: 1.8,
                }}
              >
                🚀 Led high-impact projects at ZeroToil with 30k+ downloads
                <br />
                💻 Developed full-stack solutions for manufacturing automation
                <br />
                🌐 Created responsive web applications for community platforms
                <br />
                🛠️ Expertise in Java, JavaScript, Python, and modern frameworks
              </MotionTypography>
            </MotionBox>

            <MotionBox
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              <MotionTypography
                variant="h3"
                component={motion.div}
                variants={itemVariants}
                sx={{
                  color: theme.palette.text.primary,
                  mb: 3,
                  background: 'linear-gradient(45deg, #4D9FFF, #9C4DFF)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Let's Connect
              </MotionTypography>

              <MotionBox
                variants={itemVariants}
                sx={{
                  display: 'flex',
                  gap: 2,
                  mb: 3,
                  '& .MuiIconButton-root': {
                    transition: 'all 0.3s ease',
                    background: 'rgba(255, 255, 255, 0.05)',
                    backdropFilter: 'blur(10px)',
                    padding: '16px',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      background: 'rgba(77, 255, 181, 0.1)',
                      color: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <IconButton
                  href="https://github.com/Kihsomray"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <GitHubIcon fontSize="large" />
                </IconButton>
                <IconButton
                  href="https://linkedin.com/in/myarmo"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <LinkedInIcon fontSize="large" />
                </IconButton>
                <IconButton
                  href="mailto:mike@zerotoil.com"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <EmailIcon fontSize="large" />
                </IconButton>
              </MotionBox>
            </MotionBox>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
            <MotionBox
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              sx={{
                position: 'relative',
                width: '100%',
                paddingBottom: '100%',
                borderRadius: '20px',
                overflow: 'hidden',
                boxShadow: '0 0 30px rgba(77, 255, 181, 0.3)',
                border: '1px solid rgba(77, 255, 181, 0.2)',
                transform: 'perspective(1000px) rotateY(-5deg)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'perspective(1000px) rotateY(0deg)',
                  boxShadow: '0 0 40px rgba(77, 255, 181, 0.4)',
                },
              }}
            >
              <Box
                component="img"
                src="/images/profile_picture.jpg"
                alt="Michael Yarmoshik"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
            </MotionBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
} 