import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Box,
  Container,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';

const MotionBox = motion(Box);

const navItems = [
  { text: 'Home', path: '/' },
  { text: 'Projects', path: '/projects' },
  { text: 'Skills', path: '/skills' },
  { text: 'Contact', path: '/contact' },
];

export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List
      sx={{
        height: '100%',
        background: 'rgba(10, 15, 30, 0.98)',
        backdropFilter: 'blur(20px)',
        pt: 2,
      }}
    >
      {navItems.map((item) => (
        <ListItem
          key={item.text}
          component={RouterLink}
          to={item.path}
          onClick={handleDrawerToggle}
          sx={{
            py: 2,
            color: theme.palette.text.primary,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '5%',
              width: '90%',
              height: '1px',
              background: 'rgba(255, 255, 255, 0.1)',
            },
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: 'rgba(77, 255, 181, 0.05)',
            },
          }}
        >
          <ListItemText 
            primary={item.text}
            primaryTypographyProps={{
              fontSize: '1.2rem',
              fontWeight: 500,
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        elevation={scrolled ? 0 : 0}
        sx={{
          background: scrolled 
            ? 'rgba(10, 15, 30, 0.8)'
            : 'transparent',
          backdropFilter: scrolled ? 'blur(20px)' : 'none',
          borderBottom: scrolled 
            ? '1px solid rgba(255, 255, 255, 0.1)'
            : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ px: { xs: 0 } }}>
            <MotionBox
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                mr: 4,
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Box
                sx={{
                  fontFamily: 'Cal Sans, sans-serif',
                  fontSize: '1.8rem',
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  background: 'linear-gradient(135deg, #4DFFB5 0%, #4D9FFF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  filter: 'drop-shadow(0 0 20px rgba(77, 255, 181, 0.3))',
                }}
              >
                MY
              </Box>
            </MotionBox>

            <Box sx={{ flexGrow: 1 }} />

            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: 'flex', gap: 1 }}>
                {navItems.map((item) => (
                  <MotionBox
                    key={item.text}
                    whileHover={{ y: -2 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Button
                      component={RouterLink}
                      to={item.path}
                      sx={{
                        color: location.pathname === item.path
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                        fontSize: '1rem',
                        px: 3,
                        position: 'relative',
                        overflow: 'hidden',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '2px',
                          background: theme.palette.primary.main,
                          transform: location.pathname === item.path
                            ? 'scaleX(1)'
                            : 'scaleX(0)',
                          transformOrigin: 'right',
                          transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover': {
                          color: theme.palette.primary.main,
                          backgroundColor: 'transparent',
                          '&::after': {
                            transform: 'scaleX(1)',
                            transformOrigin: 'left',
                          },
                        },
                      }}
                    >
                      {item.text}
                    </Button>
                  </MotionBox>
                ))}
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            width: '100%',
            background: 'transparent',
            boxSizing: 'border-box',
          },
        }}
      >
        <AnimatePresence>
          {mobileOpen && (
            <MotionBox
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              {drawer}
            </MotionBox>
          )}
        </AnimatePresence>
      </Drawer>
      <Toolbar />
    </>
  );
} 