import React from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  useTheme,
  Grid,
  Paper,
} from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);
const MotionTypography = motion(Typography);

const contactMethods = [
  {
    icon: <EmailIcon sx={{ fontSize: 48 }} />,
    title: 'Email',
    value: 'mike@zerotoil.com',
    link: 'mailto:mike@zerotoil.com',
    color: '#00ffa3',
  },
  {
    icon: <GitHubIcon sx={{ fontSize: 48 }} />,
    title: 'GitHub',
    value: 'Kihsomray',
    link: 'https://github.com/Kihsomray',
    color: '#ff3366',
  },
  {
    icon: <LinkedInIcon sx={{ fontSize: 48 }} />,
    title: 'LinkedIn',
    value: 'myarmo',
    link: 'https://linkedin.com/in/myarmo',
    color: '#4a9eff',
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

export default function Contact() {
  const theme = useTheme();

  return (
    <MotionContainer
      maxWidth="lg"
      sx={{ mt: { xs: 8, md: 12 }, mb: 8 }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <MotionTypography
          variant="h2"
          component={motion.div}
          variants={itemVariants}
          sx={{
            color: theme.palette.text.primary,
            mb: 3,
            background: 'linear-gradient(45deg, #00ffa3, #64ffda)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Let's Work Together
        </MotionTypography>
        <MotionTypography
          variant="h5"
          component={motion.div}
          variants={itemVariants}
          sx={{
            color: theme.palette.text.secondary,
            maxWidth: '600px',
            mx: 'auto',
            lineHeight: 1.6,
          }}
        >
          I'm always excited to connect with fellow developers, potential collaborators,
          and anyone interested in creating amazing digital experiences.
        </MotionTypography>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {contactMethods.map((method, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <MotionPaper
              variants={itemVariants}
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '4px',
                  background: method.color,
                  borderRadius: '4px 4px 0 0',
                },
                '&:hover': {
                  transform: 'translateY(-8px)',
                  transition: 'transform 0.3s ease',
                  '& .contact-icon': {
                    color: method.color,
                    transform: 'scale(1.1)',
                  },
                },
              }}
            >
              <Box
                className="contact-icon"
                sx={{
                  color: theme.palette.text.primary,
                  mb: 2,
                  transition: 'all 0.3s ease',
                }}
              >
                {method.icon}
              </Box>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.text.primary,
                  mb: 1,
                  fontWeight: 600,
                }}
              >
                {method.title}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  mb: 3,
                  fontSize: '1.1rem',
                }}
              >
                {method.value}
              </Typography>
              <Button
                variant="outlined"
                href={method.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  mt: 'auto',
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                  color: theme.palette.text.primary,
                  padding: '10px 24px',
                  fontSize: '1rem',
                  '&:hover': {
                    borderColor: method.color,
                    color: method.color,
                    backgroundColor: `${method.color}10`,
                  },
                }}
              >
                Connect
              </Button>
            </MotionPaper>
          </Grid>
        ))}
      </Grid>
    </MotionContainer>
  );
} 