import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import InteractiveBackground from './components/InteractiveBackground';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Skills from './pages/Skills';
import usePageTracking from './hooks/usePageTracking';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4DFFB5',  // Bright neon mint
      light: '#72FFD6',
      dark: '#00CC8E',
    },
    secondary: {
      main: '#FF4D8C',  // Neon pink
      light: '#FF72A5',
      dark: '#CC005C',
    },
    background: {
      default: 'transparent',
      paper: 'rgba(16, 23, 42, 0.7)',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#94A3B8',
    },
    custom: {
      neonBlue: '#4D9FFF',
      neonPurple: '#9C4DFF',
      neonOrange: '#FF8B4D',
      darkBlue: '#1E293B',
      deepBlue: '#0F172A',
    },
  },
  typography: {
    fontFamily: '"Cal Sans", "Space Grotesk", -apple-system, BlinkMacSystemFont, sans-serif',
    h1: {
      fontSize: 'clamp(48px, 10vw, 96px)',
      fontWeight: 700,
      letterSpacing: '-0.03em',
      lineHeight: 1,
      backgroundImage: 'linear-gradient(135deg, #4DFFB5 0%, #4D9FFF 50%, #9C4DFF 100%)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      filter: 'drop-shadow(0 0 2em rgba(77, 255, 181, 0.2))',
    },
    h2: {
      fontSize: 'clamp(32px, 6vw, 56px)',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 'clamp(24px, 4vw, 36px)',
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    body1: {
      fontSize: '18px',
      lineHeight: 1.8,
      letterSpacing: '0.01em',
    },
    body2: {
      fontSize: '16px',
      lineHeight: 1.6,
      letterSpacing: '0.01em',
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 500,
          padding: '12px 24px',
          transition: 'all 0.3s ease-in-out',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(45deg, rgba(77, 255, 181, 0.15), rgba(156, 77, 255, 0.15))',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
          },
          '&:hover::before': {
            opacity: 1,
          },
        },
        contained: {
          background: 'linear-gradient(45deg, #4DFFB5, #4D9FFF)',
          boxShadow: '0 0 20px rgba(77, 255, 181, 0.3)',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 0 30px rgba(77, 255, 181, 0.4)',
          },
        },
        outlined: {
          borderColor: 'rgba(77, 255, 181, 0.3)',
          '&:hover': {
            borderColor: '#4DFFB5',
            transform: 'translateY(-2px)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: 'rgba(16, 23, 42, 0.7)',
          backdropFilter: 'blur(20px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '24px',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 8px 40px rgba(77, 255, 181, 0.15)',
            borderColor: 'rgba(77, 255, 181, 0.3)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'rgba(16, 23, 42, 0.7)',
          backdropFilter: 'blur(20px)',
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            borderColor: 'rgba(77, 255, 181, 0.3)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(10, 15, 30, 0.8)',
          backdropFilter: 'blur(20px)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
  },
});

// Create a separate component for the app content that uses the tracking hook
function AppContent() {
  usePageTracking();
  
  return (
    <div style={{ 
      minHeight: '100vh',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <InteractiveBackground />
      <div style={{ position: 'relative', zIndex: 1 }}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router basename={process.env.PUBLIC_URL}>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
